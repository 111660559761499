import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Col } from "react-bootstrap"

const Contact = ({ location }) => {
	const { file } = useStaticQuery(
		graphql`
			query {
				file(relativePath: { eq: "logo/logo.png" }) {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, height: 100, quality: 100),
					}
				}
			}
		`
	)
	return (
		<Layout location={location}>
			<SEO title="Contact"/>
			<Row style={{ textAlign: 'center', fontSize: '25px', paddingTop: '25px' }}>
				<Col xs={12}>
					<h2>
						Pour tout renseignement complémentaire n'hésitez pas à nous contacter!
					</h2>
				</Col>
				<Col xs={12} style={{ marginTop: '40px', marginBottom: '40px', textAlign: 'center' }}>
					<GatsbyImage alt="logo" image={file.childImageSharp.gatsbyImageData} />
				</Col>
				<Col xs={12}>
					<address style={{ border: '1px dashed #328925', padding: '25px' }}>
						<strong>Institut Science Infirmière</strong><br/>
						Rue Saint-Léger 2bis<br/>
						1205 Genève<br/>
						Suisse<br/>
						<a href="mailto:institut-science-infirmiere.ch">info@institut-science-infirmiere.ch</a>
					</address>
				</Col>
			</Row>
		</Layout>
	)
}

export default Contact

